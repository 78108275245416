<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <h4 id="traffic" class="card-title mb-0">Listas - Whatsapp Marketing</h4>
            <div class="small text-muted">Administración de listas de contactos</div>
          </b-col>

          <b-col sm="5">
            <b-button @click="add()" type="button" variant="primary" size="sm" class="btn-pill float-right ml-2" v-b-tooltip.hover title="Agregar Contacto">
              <i class="fa fa-plus"></i> Agregar
            </b-button>                       

            <b-col sm="12" class="d-none d-md-block" v-if="!table.mostrarFiltros">
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" @click="table.mostrarFiltros=true" v-b-tooltip.hover title="Mostrar filtros">
                <i class="fa fa-filter"></i> Mostrar Filtros
              </b-button>
            </b-col>
            <b-col sm="12" class="d-none d-md-block" v-else>
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" v-if="table.mostrarFiltros" @click="table.mostrarFiltros=false" v-b-tooltip.hover title="Ocultar filtros">
                <i class="fa fa-window-close"></i>
              </b-button>
            </b-col>            
          </b-col>  
        </b-row>
      </b-card>
      
      <b-card header-tag="header" footer-tag="footer">
        <b-row v-if="table.mostrarFiltros">
          <b-col sm="4">
            <b-form-group>
              <b-input-group>
                <b-form-input type="text" size="sm" placeholder="Ingrese su busqueda" v-model="table.filter"></b-form-input>
                <b-input-group-prepend>
                  <b-button variant="dark" size="sm" :disabled="!table.filter" @click="table.filter = ''">
                    <i class="fa fa-filter"></i> Filtrar
                  </b-button>
                </b-input-group-prepend>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-table class="mb-0"
                    responsive="sm"
                    head-variant="dark"
                    :hover="true"
                    :small="true"
                    :fixed="false"
                    :items="table.items"
                    :fields="table.fields"
                    :filter="table.filter"
                    :current-page="table.currentPage"
                    :per-page="table.perPage"
                    v-if="table.items.length">

                <template v-slot:cell(id)="data">
                  <b>{{data.item.id}}</b>
                </template> 

                <template v-slot:cell(reference)="data">
                  <b-badge variant="secondary">{{data.item.reference}}</b-badge>
                </template> 

               <template v-slot:cell(name)="data">
                  <b>{{data.item.name}}</b>
                </template>

                <template v-slot:cell(contacts)="data">
                  <div v-if="data.item.cant_contacts>1">
                    <b>{{data.item.cant_contacts}}</b> Contactos
                  </div>
                  <div v-else>
                    <b>{{data.item.cant_contacts}}</b> Contacto
                  </div>
                </template>                

                <template v-slot:cell(observations)="data">
                  <div v-if="data.item.observations">
                    <div v-if="data.item.observations.length >150" :title="data.item.observations">
                      {{data.item.observations.substr(0, 150)}}
                    </div>
                    <div v-else>
                      {{data.item.observations}}
                    </div>
                  </div>
                </template>

                <template v-slot:cell(active)="data">
                  <div v-if="data.item.active">
                    <b-badge variant="success">Activo</b-badge>
                  </div>
                  <div v-else>
                    <b-badge variant="danger">Inactivo</b-badge>
                  </div>
                </template>
               
                <template v-slot:cell(f_action)="data">
                  <b-dropdown right text="Acción" size="sm" variant="outline-dark" class="pull-right">                    
                    <b-dropdown-item @click="openLinkSuscription(data.item)">
                      <b-icon icon="link"></b-icon> Link Suscripción                      
                    </b-dropdown-item>

                    <b-dropdown-header>Acciones</b-dropdown-header>
                    <b-dropdown-item @click="edit(data.item)">
                      <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
                    </b-dropdown-item>
                    <b-dropdown-item @click="remove(data.item)">
                      <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
            </b-table>
            <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
          </b-col>
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
              <i class="fa fa-angle-double-left"></i>
              Volver
            </b-button>                        
          </b-col>
          <b-col>
            <nav>
              <b-pagination class="pull-right mb-0"
                            size="sm"
                            pills=""
                            :total-rows="getRowCount(table.items)"
                            :per-page="table.perPage"
                            v-model="table.currentPage" />
            </nav>
          </b-col>            
        </b-row>
      </b-card>

      <!-- ########################### -->
      <!-- #####     MODALES     ##### -->
      <!-- ########################### -->

      <!-- CRUD MAIN -->
      <b-modal v-model="modal.form.active"              
              header-bg-variant="dark"
              header-text-variant="white">
        <div slot="modal-header">
          {{this.modal.form.title}}
        </div>
                
        <b-row>
          <b-col md="9">
            <b-form-group label="Nombre">
              <b-form-input type="text"
                            size="sm"
                            v-model="crud.form.name"
                            required
                            placeholder="Ingresar un nombre">
              </b-form-input>
            </b-form-group>                                              
          </b-col>     
          <b-col md="3">
            <b-form-checkbox v-model="crud.form.active" 
                              switch 
                              size="sm" 
                              class="mt-4 pull-right">
                Activo
            </b-form-checkbox>            
          </b-col>    
          <b-col md="12">
            <b-form-group label="Referencia" description="Identificación de una lista para la suscripción automática">
              <b-form-input type="text"
                            size="sm"
                            v-model="crud.form.reference"
                            required
                            placeholder="Ingresar una referenia">
              </b-form-input>
            </b-form-group>                                              
          </b-col>               
          <b-col md="12">
            <b-form-group label="Observaciones">
              <b-form-textarea v-model="crud.form.observations"
                                placeholder="Ingresar una observación..."
                                rows="3"
                                size="sm">
              </b-form-textarea>                  
            </b-form-group>
          </b-col>      
        </b-row>  
        
        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.form.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="save()">Guardar</b-button>          
        </div>
      </b-modal> 

      <!-- CRUD LINK SUSCRIPTION -->
      <b-modal v-model="modal.formLink.active"              
              header-bg-variant="dark"
              header-text-variant="white">
        <div slot="modal-header">
          {{this.modal.formLink.title}}
        </div>
        
        <b-row>
          <b-col md="12">
            <b-form-group label="Teléfono" description="Cod país [sin el signo] + nueve [9] + cod área [sin el 0] + nro cel [sin el 15] (total 13 dígitos)">
              <b-form-input type="number"                            
                            size="sm"
                            step="1"
                            v-model="crud.formLink.phone"                            
                            placeholder="Numero de WhatsApp conectado al sistema">
              </b-form-input>
            </b-form-group>               
          </b-col>
          <b-col md="12">
            <b-form-group label="Mensaje" description="Mensaje por defecto que recibirá el sistema. Referencia de la lista [list]">
              <b-form-textarea v-model="crud.formLink.message"
                                placeholder="Ingresar un mensaje..."
                                rows="3"
                                size="sm">
              </b-form-textarea>        
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group label="Link Suscripción" description="Copie el link al portapapeles para su distribución">
              <b-input-group size="sm">                  
                <b-form-input type="text"
                              :readonly="true"                                  
                              v-model="crud.formLink.link">
                </b-form-input>
                <b-input-group-append>
                  <b-button size="sm" text="Copiar" variant="success" @click="getLinkSuscription()">
                    <i class="fa fa-files-o"></i> Copiar
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>               
          </b-col>   
          <b-col>
            <div id="link_container"></div>
          </b-col>          
        </b-row>          
        
        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.formLink.active=false">Cerrar</b-button>             
        </div>
      </b-modal>       
    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'  
  import Funciones from '@/handler/funciones'
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import moment from 'moment'
  
  export default {     
    data: () => {
      return {      
        access: {
          module_id: Modules.WHATSAPP_MARKETING,
          profile_id: Profiles.PERSONAL,
          view_reference: 'crudLists',
          elements: {}
        }, 
        table : {
          items: [],
          fields: [
            {key: 'id', label: 'ID', sortable: true},            
            {key: 'name', label: 'Nombre'},     
            {key: 'reference', label: 'Referencia', class: 'text-left'},                               
            {key: 'contacts', label: 'Total'},            
            {key: 'observations', label: 'Observaciones'},
            {key: 'active', label: 'Activa'},
            {key: 'f_action', label:''},
          ],
          filter: null,
          mostrarFiltros: false,
          currentPage: 1,
          perPage: 50,
        },
        arr: {
          lists: []
        },
        crud: {
          form: {
            id: 0,
            reference: '',
            name: '',
            observations: '',
            active: true
          },
          formLink: {
            phone: '',
            message: '',
            reference: '',
            link: '',
          }
        },
        modal: {
          form: {
            active: false,
            title: ''
          },   
          formLink: {
            active: false,
            title: ''
          },          
        },
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    },  
    mounted() {
      this.load()
    },
    watch: {       
      'crud.form.name': function (newQuestion, oldQuestion) {                
        var name = this.crud.form.name
        if(!this.crud.form.id) {
          this.crud.form.reference = Funciones.slugify(name)
        } else {
          if(!this.crud.form.reference) {
            this.crud.form.reference = Funciones.slugify(name)
          }    
        }
      },
      'crud.form.reference': function (newQuestion, oldQuestion) {                
        this.crud.form.reference = Funciones.slugify(this.crud.form.reference)
      },      
      'crud.formLink.phone': function (newQuestion, oldQuestion) {             
        var message = this.crud.formLink.message.replace("[list]", '"'+this.crud.formLink.reference+'"');
        this.crud.formLink.link = encodeURI("https://api.whatsapp.com/send?phone=" + this.crud.formLink.phone + "&text=" + message)
      },
      'crud.formLink.message': function (newQuestion, oldQuestion) {             
        var message = this.crud.formLink.message.replace("[list]", '"'+this.crud.formLink.reference+'"');
        this.crud.formLink.link = encodeURI("https://api.whatsapp.com/send?phone=" + this.crud.formLink.phone + "&text=" + message)
      },      
    },        
    methods: {
      // TABLA
      getRowCount (items) {
        return items.length
      },
      onRowSelected(item) {      
        this.arr.lists.forEach((element, index) => {
          if(item.length){
            if(element.id == item[0].id) {
              this.openDetail(index)
            }
          }
        }) 
      },     
      openDetail(index) {
        this.table.rowSelected = index
        this.load()
      }, 

      // LISTA
      load() {
        var result = serviceAPI.obtenerWhatsappMarketingLists()

        result.then((response) => {
          var data = response.data
          this.table.items = data
          this.arr.lists = data
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error))
        });        
      },
      add() {
        this.crud.form.id = 0
        this.crud.form.reference = ''
        this.crud.form.name = ''
        this.crud.form.observations = ''
        this.crud.form.active = true

        this.modal.form.title = "Nueva Lista de Contacto"
        this.modal.form.active = true
      },
      edit(item) {
        this.crud.form.id = item.id        
        this.crud.form.reference = item.reference
        this.crud.form.name = item.name
        this.crud.form.observations = item.observations
        
        if(item.active == 1){
            this.crud.form.active = true
        } else {
            this.crud.form.active = false
        }
        
        this.modal.form.title = "Nueva Lista de Contacto"
        this.modal.form.active = true
      },
      remove(item) {
        this.crud.form.id = item.id
        this.crud.form.name = item.name

        this.$bvModal.msgBoxConfirm('¿Desea borrar el item (' + this.crud.form.id + ') ' + this.crud.form.name + '?', {
          title: 'Borrar Lista de Contacto',
          size: 'lg',
          buttonSize: 'lg',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();
            var result = serviceAPI.eliminarWhatsappMarketingLists(this.crud.form.id);

            result.then((response) => {      
              this.modal.form.active = false        
              loader.hide()
              this.load()
              this.$awn.success("Registro eliminado");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },
      save() {
        let loader = this.$loading.show();

        if (this.crud.form.id) {
          var result = serviceAPI.editarWhatsappMarketingLists(this.crud.form);
        } else {
          var result = serviceAPI.agregarWhatsappMarketingLists(this.crud.form);
        }

        result.then((response) => {
          this.modal.form.active = false
          loader.hide()
          this.load()
          this.$awn.success("Datos guardados con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },
   
      // LINK SUSCRIPCION
      openLinkSuscription(item) {        
        this.crud.formLink.phone = ''
        this.crud.formLink.message = 'Quiero suscribirme para recibir informacion sobre [list]'
        this.crud.formLink.reference = item.reference
        this.crud.formLink.link = ''

        this.modal.formLink.title = "Generador de Link de Suscripción"
        this.modal.formLink.active = true
      },
      getLinkSuscription() {
        var divContainer = document.getElementById("link_container");
        var divLink = document.createElement("div");
        divLink.setAttribute("id", 'link_suscription');
        divLink.textContent = this.crud.formLink.link;      
        divContainer.appendChild(divLink);

        var codigoACopiar = document.getElementById('link_suscription')        
        var seleccion = document.createRange(); 
        seleccion.selectNodeContents(codigoACopiar);

        window.getSelection().removeAllRanges();
        window.getSelection().addRange(seleccion); 

        try {
          var res = document.execCommand('copy');
          if (res){
            this.$awn.success("Link de Suscripción copiado en portapapeles");
          } else {
            this.$awn.alert("No se pudo copiar el Link de Suscripción");
          }    
          
          divLink.remove()
        }
        catch(ex) {
          divLink.remove()            
        }
        window.getSelection().removeRange(seleccion);
      }
    }    
  }
</script>